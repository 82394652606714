header {
  position: relative;
  width: 100%;
  height: 45px;
  background-color: #fff;
  border-bottom: 1px solid #cacaca;
  padding: 5px;
  box-shadow: 0 0 3px 0 rgba(50, 50, 50, 0.25);
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .separator {
    display: inline-block;
    margin-left: 20px;
    width: 1px;
    height: 35px;
    background-color: #ddd;
    vertical-align: middle;
  }
}
